<template>
  <div class="d-block creation__container" >
    <v-dialog
      v-model="dialog"
      :hide-overlay="loading"
      :persistent="loading"
      width="600"
    >
      <v-card
        :color="loading ? 'primary':''" 
        :dark="loading"
      >
        <!-- Loading -->
        <v-card-text v-if="loading">
          <span class="mt-2 mb-2 font-weight-bold">Please wait a minute</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <!-- Is value -->
        <v-card-text v-else>
          <v-card-title>
            <span class="primary--text">Create new invoice - Result</span>
          </v-card-title>
          <div class=" d-block w-100" v-if="dataCreateNewInvoice.object != null">
            <v-list
             two-line
            >
              <template v-for="(itemObject, index) in dataCreateNewInvoice.object">
                <v-list-item :key="`Title-${index}`">
                  <v-list-item-content>
                    <v-list-item-title v-html="index"></v-list-item-title>
                    
                    <v-list-item-subtitle v-if="index != 'invoiceGUID'" class="mt-1 font-weight-black" v-html="itemObject != '' ? itemObject : 'Empty'"></v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      <a :href="`/invoice/view-invoice-detail-by-invoice-guid/${itemObject}`" 
                        v-if="dataCreateNewInvoice.status == 0">
                        {{itemObject}}
                      </a>
                      <span v-else>Empty</span>
                    </v-list-item-subtitle>
              
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index != 'MessLog'"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-form
      ref="formContainer"
      v-model="validForm"
      lazy-validation
      >
      <v-card elevation="0" outlined style="position: relative">
        <v-card-title class="d-flex align-center w-100" >
          <span class="font-weight-black">
            Create a new invoice
          </span>
          <span class="primary--text text-subtitle-2 ml-2 d-flex align-center" style="cursor:pointer"
            @click="dialog = true"
            v-if="dataCreateNewInvoice.object != null"
            >
             <v-icon color="primary">
               mdi-file-document-outline
             </v-icon>
             <span>View result</span>
          </span>
          <OpenEhoadon
            position="absolute"
            right="10px"
            top="10px"
          />
        </v-card-title>
        <v-card-text>
        <v-row>
          <v-col cols="12">
            <CreationForm 
              ref="CreationInvoiceForm"
              @create-new-invoice="takeData"
              @load-payment-sumary-id="loadPaymentSummaryID"
            />
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>

      <div class="d-block w-100" >
        <div class="footer__creation__form">
          <div class="footer__creation__action">
            <v-btn color="primary"
              @click="saveDataForm"
              :disabled="loading"
              :loading="loading"
              >
              Create new
            </v-btn>

            <v-btn 
              color="warning" 
              class="ml-1"
              :disabled="loading"
              @click="resetAllToDefault"
              >
              Reset all
            </v-btn>
          </div>
          
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import InvoiceService from '@/services/invoice'
import CreationForm from './CreatationForm'
import OpenEhoadon from './OpenEhoadon'
import {EInvoiceStatus} from '@/plugins/constant'
export default {
  name: "CreateNewInvoiceComponent",

  components: {
    CreationForm,
    OpenEhoadon
  },

  created() {
    if (this.$route.params.summaryPaymentID) {
      this.paymentSummaryID = this.$route.params.summaryPaymentID;
      this.loadShortDetailPaymentSummary()
    }
  },

  data: () => {
    return {
      EInvoiceStatus,
      validForm: true,
      dataInsert: {},
      loading: false, 
      dialog: false,
      dataCreateNewInvoice: {
        isError: false,
        isOk: false,
        error_messages: '',
        object: null,
        status: 0
      },
      paymentSummaryID: null,
    }
  },
  methods: {
    async saveDataForm() {
      if (this.$refs.formContainer.validate()) {
        this.loading = true;
        this.dialog = true;
        this.$refs.CreationInvoiceForm.disableDiv(true)
        let data = this.$refs.CreationInvoiceForm.emitRealDataForCreation();
        
        var creatingNewInvoice = await InvoiceService.createANewInvoice(data, this.paymentSummaryID);
        if (creatingNewInvoice.error) {
          this.loading = false;
          this.dialog = false;
          this.$refs.CreationInvoiceForm.disableDiv(false)
          this.$toast.error("Something went wrong when try to action!");
          this.dataCreateNewInvoice = {
            isError: false,
            isOk: false,
            error_messages: '',
            object: null,
            status: 1 // FAIL , 0 => SUCCESS
          }
          return;
        }
      
        this.loading = false;
        this.$refs.CreationInvoiceForm.disableDiv(false)
        this.dataCreateNewInvoice = {
          isError: creatingNewInvoice.isError,
          isOk:  creatingNewInvoice.isOk,
          object: creatingNewInvoice.object,
          status: creatingNewInvoice.status,
        }
      } else {
        this.$toast.error('Some input need be filled!')
      }
    },
    takeData(data) {
      console.log(data);
    },
    loadPaymentSummaryID(item) {
      this.paymentSummaryID = item.paymentSummaryID;
    },
    async loadShortDetailPaymentSummary() {
      var result = await InvoiceService.getShortDetailPaymentSummary(this.paymentSummaryID);
      if (result.error) {
        this.$toast.error("Something went wrong when try to get the payment info!");
        return;
      }
      // success -> push info to creation form
      this.$refs.CreationInvoiceForm.pushPaymentInfoToListInput(result)
    },

    resetAllToDefault() {
       
      this.$refs.CreationInvoiceForm.resetListInputToDefault();
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
.footer__creation__form {
  width: inherit;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px 0px 5px 0px;
  max-width: inherit;
  display: flex;
  justify-content: center;
  z-index: 99;
  backdrop-filter: blur(2px);
  background: rgba(207, 207, 207, 0.75);
}
.creation__container {
  width: 100%;
}
.footer__creation__form {
  padding: 5px;
  z-index:100;
  height: inherit;
}
</style>