<template>
  <v-tooltip bottom
    content-class="active-class"
    >
    <template v-slot:activator="{ on, attrs }">

      <v-chip 
        class="d-flex align-center pa-2 cursor-pointer"
        color="primary"
        small
        label   
        v-bind="attrs"
        v-on="on"
        @click.stop="openTabEHoaDon"
        v-bind:style="{
          position: position, 
          top: top ? top : '', 
          right: right ? right : '',
          left: left ? left : '',
          bottom: bottom ? bottom : ''
        }"
        >
        <v-icon small>
          mdi-open-in-new
        </v-icon>
        <span class="ml-1">EHoaDon</span>
      </v-chip>
     
    </template>
    <div class="d-block">
      <label class="ma-0 pa-0 d-block">
        <span class="text-subtitle-1 font-weight-bold">User:</span>
        <span class="text-subtitle-2 font-weight-bold ml-1">0302593596</span>
      </label>
      <label  class="ma-0 pa-0 d-block">
        <span class="text-subtitle-1 font-weight-bold">Pass:</span>
        <span class="text-subtitle-2 font-weight-bold ml-1">82534738</span>
      </label>
    </div>
  </v-tooltip>
</template>
<script>
export default { 
  props: {
    position: { 
      type: String,
      default: 'static'
    },
    top: {
      type: String, 
      default: '',
    },
    right: {
      type: String, 
      default: '',
    },
    left: {
      type: String,
      default: '',
    },
    bottom: {
      type: String,
      default: ''
    }
  },
  data: () => {
 
    return {
      urlEhoaDon: 'https://www.bkav.com.vn/ehoadon'
    }
  },
  methods: {
    openTabEHoaDon() {
      window.open(this.urlEhoaDon);
    }
  }

}
</script>

<style scoped>
.active-class {
  background-color: eaeaea;
}
.cursor-pointer {
  cursor: pointer;
}
</style>