var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":"","content-class":"active-class"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"d-flex align-center pa-2 cursor-pointer",style:({
        position: _vm.position, 
        top: _vm.top ? _vm.top : '', 
        right: _vm.right ? _vm.right : '',
        left: _vm.left ? _vm.left : '',
        bottom: _vm.bottom ? _vm.bottom : ''
      }),attrs:{"color":"primary","small":"","label":""},on:{"click":function($event){$event.stopPropagation();return _vm.openTabEHoaDon($event)}}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")]),_c('span',{staticClass:"ml-1"},[_vm._v("EHoaDon")])],1)]}}])},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"ma-0 pa-0 d-block"},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("User:")]),_c('span',{staticClass:"text-subtitle-2 font-weight-bold ml-1"},[_vm._v("0302593596")])]),_c('label',{staticClass:"ma-0 pa-0 d-block"},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("Pass:")]),_c('span',{staticClass:"text-subtitle-2 font-weight-bold ml-1"},[_vm._v("82534738")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }