<template>
  <div class="d-block">
    <v-dialog
      v-model="dialogLastVisit"
      scrollable  
      width="1000px"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          color="primary"
          :loading="listLastVisit.loading"
          v-bind="attrs"
          v-on="on"     
        >View list visitation</v-btn>
      </template>
      <v-card>
        <v-card-title class="position-relative">
          <span>List Visitation of Patient: <b class="blue--text">{{ this.patientID }}</b></span>
        </v-card-title>
        <v-card-text>
          <template v-if="listLastVisit.items.length > 0">
            <div class="d-flex justify-space-between align-center w-100" >
              <span class="text-subtitle-1 font-weight-black">TOTALS: <b class="primary--text">{{ listLastVisit.items.length }}</b></span>
            </div>
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, index) in listLastVisit.items" :key="index">
                <!-- Header of expansion -->
                <v-expansion-panel-header>
                  {{ item.dateCreated }}
                  <template #default>
                    <div class="episode__content">
                      <!-- Date -->
                      <div class="item">
                        <v-icon class="mr-2">
                          mdi-calendar
                        </v-icon>
                        <span
                          :class="{ 'blue--text': moment(item.dateCreated).format('DD-MM-YYYY') == moment().format('DD-MM-YYYY') }"
                          >
                          {{  moment(item.dateCreated).format("DD-MM-YYYY") == moment().format("DD-MM-YYYY") 
                                    ? 'TODAY' 
                                    : moment(item.dateCreated).format("DD-MM-YYYY") }}
                          </span>
                      </div>
                      <!-- Status -->
                      <div class="item">
                        <span class="mr-2">Status:</span>
                        <template v-if="item.statusID == 2">
                          <span class="green--text">PAID</span>
                          <v-icon color="green" class="ml-1" small>
                            mdi-check
                          </v-icon>
                        </template>
                        <template v-if="item.statusID == 1">
                          <span class="blue--text">In-Progress</span>
                          <v-icon color="blue" class="ml-1" small>
                            mdi-run
                          </v-icon>
                        </template>
                        <template v-if="item.statusID == -1">
                          <span class="red--text">DELETE</span>
                          <v-icon color="red" class="ml-1" small>
                            mdi-close-thick
                          </v-icon>
                        </template>
                      </div>
                      <!-- FINAL PRICE -->
                      <div class="item">
                        <v-icon class="mr-2">
                          mdi-cash-usd-outline
                        </v-icon>
                        <span>{{ currencyFormat(item.finalPrice, 'end', ' VNĐ') }}</span>
                      </div>
                      <!-- Action  -->
                      <div class="item">
                        <template>
                          <span v-if="!item.selected"
                            class="blue--text d-flex align-center"
                            @click.stop="exportInvoiceBKAV(item)"
                            > 
                            Export Invoice
                            <v-icon class="ml-1" color="blue" small>
                              mdi-hand-pointing-up
                            </v-icon>
                          </span>
                        </template>
                       
                      </div>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <!-- Content of expansion -->
                <v-expansion-panel-content>
                  <template v-if="item.listOderItem.length > 0">
                    <v-list 
                      two-line
                    >
                      <v-subheader class="text-subtitle-1 font-weight-bold">List Order Items</v-subheader>
                      <v-list-item v-for="(itemOrder, indexOrder) in item.listOderItem" :key="indexOrder">
                        <v-list-item-content>
                          <v-list-item-title>
                            <div class="d-flex flex-row flex-wrap justify-space-between">
                              <span class="font-weight-bold text-subtitle-1">
                                {{ indexOrder + 1 }}./  
                                {{ itemOrder.description }}
                              </span>
                              <span class="font-weight-bold">
                                <label>Status:</label>
                                <label 
                                  :class="{ 'blue--text': itemOrder.completionID == 2, 'warning--text': itemOrder.completionID == 1, 'green--text': itemOrder.completionID == 3}"
                                >
                                  {{ itemOrder.completionID == 2 ? 'IN PROGRESS' : ''}}
                                  {{ itemOrder.completionID == 1 ? 'WAITING' : '' }}
                                  {{ itemOrder.completionID == 3 ? 'FINISH': '' }}
                                </label>
                              </span>
                            </div>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <div class="d-flex flex-row flex-wrap justify-space-between align-center mt-1">
                              <label>
                                <span class="font-weight-bold">Free:</span>
                                <span 
                                  :class="{ 'red--text': !itemOrder.isFree, 'blue--text': itemOrder.isFree}">
                                  <b class="ml-1">{{ itemOrder.isFree ? 'YES' : 'NO' }}</b>
                                </span>
                              </label>
                            </div>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <div class="d-flex flex-row flex-wrap justify-space-between align-center mt-1">
                              <label>
                                <span class="font-weight-bold">Promotion:</span>
                                <span 
                                  :class="{ 'red--text': itemOrder.promotionID == null, 'blue--text': itemOrder.promotionID != null}">
                                  <b class="ml-1">{{ itemOrder.promotionID != null ? 'YES' : 'NO' }}</b>
                                </span>
                              </label>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                  <template v-else>
                    <span class="red--text">This visit did not have any order items! ...</span>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- view more -->
            <div class="d-flex justify-end mt-1 font-weight-bold">
              <span class="primary--text" style="cursor:pointer"
                @click="viewMore"
                >View more...</span>
            </div>
          </template>
          <template v-else>
            <span class="red--text">No found any episode!</span>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import { currencyFormat } from '@/plugins/helper'
import moment from "moment";
import InvoiceService from '@/services/invoice'
export default {
  name: 'LastVisitInvoiceComponent',
  props: {
    patientID: null,
  },
  
  watch: {
    
  },
  data: () => {
    return {
      exportAll: false,
      listLastVisit: {
        items: [],
        error: false,
        error_messages: '',
        loading: false,
        limit: 3,
      },
      dialogLastVisit: false,
    }
  },
  mounted() {
    this.loadLastVisitAndPayment();
  },
  methods: {
    moment,
    currencyFormat,
    async loadLastVisitAndPayment() {
      if (this.patientID == null)
        return;
      this.listLastVisit = {
        ...this.listLastVisit,
        error: false,
        error_messages: [],
        loading: true,
      }
      var result = await InvoiceService.getPaymentAndLastVisit(this.patientID, 1, this.listLastVisit.limit);
      if (result.error) {
        this.listLastVisit = {
          ...this.listLastVisit,
          error: true,
          error_messages: 'Can load list visitation of patient!',
          loading: false
        }
        return;
      }

      this.listLastVisit = {
        ...this.listLastVisit,
        items: result.map(i => { i.selected = false; return i}), 
        error: false,
        error_messages: '',
        loading: false,
      }
    },
    exportInvoiceBKAV(item) {
      // map selected
      this.listLastVisit = {
        ...this.listLastVisit,
        items: this.listLastVisit.items.map(i => { 
          if (i.paymentSummaryID == item.paymentSummaryID) {
            i.selected = true;
          } else {
            i.selected = false;
          }
          return i
        })
      }
      // emit data to parent
      this.$emit('load-payment', item);    
      // close dialog
      this.dialogLastVisit = false 
    },

    changeExportAll(value) {
      if (this.listLastVisit.items.length) {
        this.listLastVisit.items = this.listLastVisit.items.map(item =>  {
          item.selected = value
          return item
        })
      }
    },

    viewMore() {
      this.listLastVisit.limit += 3;
      this.loadLastVisitAndPayment()
    },
  }

}
</script>

<style scoped lang="scss">
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.episode__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .item {
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

</style>