<template>
  <div class="d-block pa-2"> 
    <v-row 
        v-for="(item, index) in detailInvoiceInput" 
        :key="index" 
        class="padding: 5px;"
        justify="space-between"
      >
      <v-col xl="5" lg="5" md="5" sx="12" sm="12" style="padding: 3px; ">
        <label class="font-weight-bold text-subtitle-2">{{ item.label }}</label>
      </v-col>
      <v-col xl="6" lg="6" md="7" sx="12" sm="12" style="padding: 3px; ">
        <template v-if="item.input == 'text'">
          <v-text-field 
            dense
            v-model="item.value"
            :disabled="item.disabled"
            :type="item.type ? item.type : ''"
            v-bind:class="item.class"
            v-bind:style="item.style"
            @change="changeInputItem(item)"
            :required="item.required"
            :rules="!batchInvoice ? item.rules : []"
          ></v-text-field>
        </template>
        <template v-if="item.input == 'select'">
            <v-select
              dense
              v-model="item.value"
              @change="changeInputItem(item)"
              :items="item.items"
              :item-text="item.display"
              :item-value="item.return"  
              v-bind:style="item.style"
              v-bind:class="item.class" 
            ></v-select>
        </template>
      </v-col>
    </v-row> 
  </div>
</template>

<script>
import InvoiceConfig from './config'
import {ConvertNumber} from '@/plugins/helper'
export default {
  name: "InvoiceDetailComponent",
  props: {
    finalPrice: {
      type: Number,
      default: 0
    },
    batchInvoice: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    finalPrice() {
      this.loadFinalPrice(this.finalPrice)
      this.takeTheRealData()
      console.log('change')
    }
  },
  mounted() {
    this.loadFinalPrice(this.finalPrice)
    this.takeTheRealData()
  },
 
  data: () => {
    return {
      detailInvoiceForm: false,
      detailInvoiceInput: [
        { 
          label: 'Tên hàng hóa, dịch vụ hoặc nội dung:',
          name: 'ItemName',
          input: 'text',  
          value: '',
          default: '',
          class: "",
          style: {padding: '0px', margin: '0px'},
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
        { 
          label: 'Đơn vị tính hàng hóa, dịch vụ:',
          name: 'UnitName',
          input: 'text',      
          value: 'lần',
          class: "",
          style: {padding: '0px', margin: '0px'}
        },
        { 
          label: 'Số lượng hàng hóa, dịch vụ:',
          name: 'Qty',
          input: 'text', 
          type: 'number',
          convert: 'int',  
          value: '1',
          default: '1',
          class: "",
          style: {padding: '0px', margin: '0px'},
          required: true,
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
        { 
          label: 'Giá của hàng hóa, dịch vụ:',
          name: 'Price',
          input: 'text',  
          type: 'number',
          convert: 'float',    
          value: 0,
          default: 0,
          class: "",
          style: {padding: '0px', margin: '0px'},
          required: true,
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
        { 
          label: 'Thành tiền hàng hóa, dịch vụ:',
          name: 'Amount',
          input: 'text', 
          type: 'number', 
          convert: 'float',     
          value: 0,
          default: 0,
          class: "",
          style: {padding: '0px', margin: '0px'},
          required: true,
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
        {
          label: 'Thuế suất:',
          name: 'TaxRateID',
          input: 'select',
          items: InvoiceConfig.ListTaxRate,
          display: 'text',
          return: 'id',
          value: 1,
          default: 1,
          class: "w-75",
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Thành tiền thuế:',
          name: 'TaxAmount',
          input: 'text',
          type: 'number',
          convert: 'float',
          value: 0,
          default: 0,
          class: "",
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Chiết khấu ghi trên hóa đơn:',
          name: 'IsDiscount',
          input: 'select',
          items: InvoiceConfig.ListIsDiscount,
          display: 'text',
          return: 'id',
          value: 0,
          default: 0,
          class: "w-50",
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Loại hàng hóa:',
          name: 'ItemTypeID',
          input: 'select',
          items: InvoiceConfig.ListItemType,
          display: 'text',
          return: 'id',
          value: 3,
          default: 3,
          class: "",
          style: {padding: '0px', margin: '0px'}
        },
      ]
    }
  },
  methods: {
    changeInputItem(item) {
     
      // business here
      let nameInput = item.name;
      switch(nameInput) { 
        case 'TaxRateID': { // Thuế suất
          let searchTax = InvoiceConfig.ListTaxRate.find(itemTax => itemTax.id == item.value);
          let amountCost = this.detailInvoiceInput.find(item => item.name == 'Amount');
          this.calculateAmountTax(amountCost, searchTax);
          break;
        } 

        case 'Amount': {
          // find item tax
          let itemInputTaxRateID = this.detailInvoiceInput.find(item => item.name == 'TaxRateID');
          let searchTax = InvoiceConfig.ListTaxRate.find(itemTax => itemTax.id == itemInputTaxRateID.value);
          this.calculateAmountTax(item, searchTax);
          break;
        }
          
      }

      // emit data to parent 
      this.takeTheRealData();      
    },
    takeTheRealData() {
      let dataInput = JSON.parse(JSON.stringify(this.detailInvoiceInput));
      let dataEmit = {};
      dataInput.reduce((obj, item) => {
        if (item.type && item.type == 'number' && item.convert) {
          obj[item.name] = ConvertNumber(item.convert, item.value);
          return obj;
        }
        obj[item.name] = item.value; 
        return obj;
      }, dataEmit)
      // emit to parent
      this.$emit('input', dataEmit);
    },

    calculateAmountTax(amountCost, taxTypeItem) {
      let cost = ConvertNumber(amountCost.convert, amountCost.value);
      let amountTax = (taxTypeItem.value) ? cost * taxTypeItem.value : 0;
      // update amountTax
      this.detailInvoiceInput = this.detailInvoiceInput.map(i => {
        if (i.name == 'TaxAmount') {
          i.value = amountTax;
        }
        return i;
      })
    }, 

    loadFinalPrice(finalPrice) {
     
      // update list Input Data
      this.detailInvoiceInput = this.detailInvoiceInput.map(i => {
        if (i.name == "Price" || i.name == "Amount") {
          i.value =  ConvertNumber(i.convert, finalPrice);
        }
        return i;
      })
      // emit to parent
      this.takeTheRealData();
    },

    loadItemNameService(itemName) {
      this.detailInvoiceInput = this.detailInvoiceInput.map(i => {
        if (i.name == "ItemName") {
          i.value = itemName
        }
        return i;
      })
      // emit to parent
      this.takeTheRealData();
    },
    
    resetToDefault() {
      this.detailInvoiceInput = this.detailInvoiceInput.map(i => {
        if (typeof i.default != 'undefined') {
          i.value = i.default
        }
        return i
      })
    }
  }
}
</script>

<style scoped>
 .w-15 {
   width: 15%;
 }
 .w-25 {
   width: 25%;
 }
 .w-50 {
   width: 50%;
 }
 .w-75 {
   width: 75%;
 }
</style>