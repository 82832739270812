<template>
  <div class="d-block" :class="{'savingCreationForm': saving}">
    <div class="d-flex align-center w-50 mb-2 mt-2" v-if="!batchInvoice">
      <v-text-field
        label="Search PID"
        v-model="patient.id"
        class="w-50"
       
        persistent-hint
        clearable
        :error="patient.error"
        :error-messages="patient.error_messages"
        @keydown.enter="searchPatient"
      ></v-text-field>

      <v-btn 
        @click="searchPatient"
        :loading="patient.loading"
        small 
        class="ml-3" 
        color="primary"
        >
        Search</v-btn>

        <!-- List visitation -->
        <LastVisitInvoice 
          ref="ViewListLastVisitComponent"
          class="ml-1"
          @load-payment=loadPaymentToInvoiceDetail  
          :patientID="patient.id"/>
    </div>

    <v-row justify="space-between">
      <!-- Form create new invoice -->
      <v-col md="6" xl="6" sm="12" sx="12">
        <v-row v-for="(itemInput, index) in listInputs" :key="index" >
          <v-col xl="4" lg="4" md="5" sx="12" sm="12">
            <label class="font-weight-bold text-subtitle-2">{{ itemInput.label }}</label>
          </v-col>
          <v-col xl="8" lg="8" md="7" sx="12" sm="12">
            <template v-if="itemInput.input == 'select'">
              <v-select 
                dense
                v-model="itemInput.value"
                :items="itemInput.items"
                :item-text="itemInput.display"
                :item-value="itemInput.return"
                v-bind:style="itemInput.style"
                v-bind:class="itemInput.class" 
              ></v-select>
            </template>
            <template v-if="itemInput.input == 'datePicker'">
              <v-text-field
                dense
                v-model="itemInput.value"         
                v-bind:style="itemInput.style"
                v-bind:class="itemInput.class"
              ></v-text-field>    
            </template>
            <template v-if="itemInput.input == 'text'">
              <v-text-field 
                dense
                v-model="itemInput.value"
                :disabled="itemInput.disabled"
                v-bind:class="itemInput.class"
                v-bind:style="itemInput.style"
                :required="itemInput.required"
                :rules="itemInput.rules"
              ></v-text-field>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <!-- List detail invoices -->
      <v-col  md="6" xl="6" sm="12" sx="12" >   
        <v-card 
          elevation="0"
          outlined 
          style="position: relative"
          >
          <v-card-text>
            <v-sheet  v-bind:class="{blurTabContent: batchInvoice}">
              <v-card-title>
                <span class="text-subtitle-1 font-weight-bold mr-2">Chi tiết hóa đơn</span>
                <v-btn icon small color="primary" @click="addMoreDetailInvoice">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-title>

              <v-tabs
                v-model="tabInvoice"
                show-arrows
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab 
                  v-for="(itemTab, indexTab) in listDetailInvoice" 
                  :key="indexTab"
                  >
                  <label class="mr-1">Chi tiết HĐ {{ itemTab.order }}</label>
                  <template v-if="itemTab.canRemove">
                    <v-btn icon small
                      @click.stop="removeTab(itemTab.order)"
                      >
                      <v-icon small color="red">
                          mdi-close
                      </v-icon>
                    </v-btn>    
                  </template>
                </v-tab>
              </v-tabs>
              <!-- Tab content -->
              <v-tabs-items
                class="mt-1"
                v-model="tabInvoice"
                eager
                >
                <v-tab-item
                  eager
                  v-for="(itemTab, indexTab) in listDetailInvoice" :key="indexTab"
                  >
                  <v-card flat>
                    <v-card-text>
                      <InvoiceDetail 
                        :ref="`DetailInvoice`"
                        v-model="itemTab.data"
                        :finalPrice="itemTab.finalPrice"
                        :batchInvoice=batchInvoice
                      />
                    </v-card-text>
                  </v-card>
                  </v-tab-item>
              </v-tabs-items>
            </v-sheet> 

            <div class="disableAction" v-if="batchInvoice">
                <div class="disableAction__content"> 
                    <v-chip
                      label
                      color="red"
                      text-color="white"
                    >Disable block</v-chip>
                </div>
            </div>       
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
   
  </div>
</template>

<script>
import moment from "moment";
import InvoiceConfig from './config'
import InvoiceDetail from './InvoiceDetail';
import PatientService from '@/services/patient'
import LastVisitInvoice from './LastVisitInvoice'
import InvoiceService from '@/services/invoice'
export default {
  name: "CreationFormComponent",
  components: {
    InvoiceDetail,
    LastVisitInvoice
  },
  props: {
    batchInvoice: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      validNewInvoice: false,
      listInputs: [
        { 
          label: 'Loại hóa đơn:',
          name: 'InvoiceTypeID',
          input: 'select',
          items: InvoiceConfig.InvoiceType,
          display: 'text',
          return: 'id',
          value: 1,
          default: 1,
          class: "w-75",
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Ngày trên hóa đơn:',
          name: 'InvoiceDate',
          ref: 'RefInvoiceDate',
          input: 'datePicker',
          value: moment().format('YYYY-MM-DD'),
          default: moment().format('YYYY-MM-DD'),
          class: "w-50",
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Tên người mua hàng:',
          name: 'BuyerName',
          input: 'text',
          value: '',
          default: '',
          style: {padding: '0px', margin: '0px'},
          required: true,
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
        {
          label: 'Tên đơn vị mua hàng:',
          name: 'BuyerUnitName',
          input: 'text',
          value: '',
          default: '',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Địa chỉ đơn vị mua hàng:',
          name: 'BuyerAddress',
          input: 'text',
          value: '',
          default: '',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Mã số thuế Người mua hàng:',
          name: 'BuyerTaxCode',
          input: 'text',
          value: '',
          default: '',
          class: 'w-50',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Thông tin tài khoản ngân hàng người mua:',
          name: 'BuyerBankAccount',
          input: 'text',
          value: '',
          default: '',
          class: '',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Hình thức thanh toán:',
          name: 'PayMethodID',
          input: 'select',
          items: InvoiceConfig.PaymentMethod,
          display: 'text',
          return: 'id',
          value: 1,
          default: 1,
          class: "w-50",
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Hình thức nhận Hoá đơn:',
          name: 'ReceiveTypeID',
          input: 'select',
          items: InvoiceConfig.ReceiveTypeID,
          display: 'text',
          return: 'id',
          value: 1,
          default: 1,
          class: "w-50",
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Email nhận hóa đơn:',
          name: 'ReceiverEmail',
          input: 'text',
          value: '',
          default: '',
          class: '',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Số điện thoại nhận Hoá đơn:',
          name: 'ReceiverMobile',
          input: 'text',
          value: '',
          default: '',
          class: '',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Địa chỉ nhận Hoá đơn:',
          name: 'ReceiverAddress',
          input: 'text',
          value: '',
          default: '',
          class: '',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Tên người nhận Hoá đơn: ',
          name: 'ReceiverName',
          input: 'text',
          value: '',
          default: '',
          class: '',
          style: {padding: '0px', margin: '0px'},
          required: true,
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
        {
          label: 'Ghi chú trên Hoá đơn: ',
          name: 'Note',
          input: 'text',
          value: '',
          default: '',
          class: '',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Mã ID chứng từ kế toán hoặc số Bill code của Hoá đơn Bán hàng: ',
          name: 'BillCode',
          input: 'text',
          value: '',
          default: '',
          class: '',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Tiền tệ:',
          name: 'CurrencyID',
          input: 'select',
          items: InvoiceConfig.ListCurrency,
          display: 'text',
          return: 'id',
          value: 'VND',
          default: 'VND',
          class: "w-50",
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Tỷ giá ngoại tệ so với VND: ',
          name: 'ExchangeRate',
          input: 'text',
          value: '1.0',
          class: 'w-50',
          style: {padding: '0px', margin: '0px'}
        },
        {
          label: 'Mẫu số Hóa đơn: ',
          name: 'InvoiceForm',
          input: 'text',
          value: '01GTKT0/001',
          disabled: true,
          class: 'w-50',
          style: {padding: '0px', margin: '0px'},
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
        {
          label: 'Ký hiệu Hóa đơn: ',
          name: 'InvoiceSerial',
          input: 'text',
          value: 'AA/21E',  // default: AA/20E
          disabled: true,
          class: 'w-50',
          style: {padding: '0px', margin: '0px'}, 
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
        {
          label: 'Số hóa đơn: ',
          name: 'InvoiceNo',
          input: 'text',
          type: 'number',
          value: 1,
          class: 'w-50',
          style: {padding: '0px', margin: '0px'},
          rules: [
            v => !!v || 'Input is required!'
          ]
        },
      ],

      patient: {
        loading: false,
        error: false,
        error_message: '',
        id: null,
        personalInfo: {
          address: "",
          athenaID: null,
          avatar: null,
          birthDate: "",
          email: "",
          ethnicityID: null,
          fullName: "",
          genderType: null,
          healthInsuranceID: null,
          identificationNumber: "",
          nationalityID: null,
          occupationID: null,
          patientID: null,
          phone: "",
        },
      },

      listDetailInvoice: [
        {
          order: 1,  // default having 1 invoice
          data: {},
          finalPrice: 0
        }
      ],
      tabInvoice: null,
      saving: false,
    }
  },
  created() {
    this.loadMaxInvoiceNo()
  },
  methods: {
    addMoreDetailInvoice() {
      let nextRecord = this.listDetailInvoice.length + 1;
      let newDetailInvoice = {
        data: {},
        order: nextRecord,
        canRemove: true
      };
      // add to List
      this.listDetailInvoice.push(newDetailInvoice);
    },
    removeTab(orderTab) {
      this.listDetailInvoice = this.listDetailInvoice
                                  .filter(i => i.order == 1 || i.order != orderTab)
                                  .map((item, index) => {                
                                    item.order = index + 1;
                                    return item;
                                  });
                        
    },
    async searchPatient() {
     
      // patient loading
      this.patient = {
        ...this.patient,
        loading: true,
        error: false,
        error_messages: '',
      }
      let patientDta = await PatientService.getPatientDetail(this.patient.id);
      if (patientDta.error) {
        this.patient =  {
          ...this.patient,
          loading: false,
          error: true,
          error_messages: 'Can not retrieve data!'
        }
        return;
      }
      this.patient = { 
        ...this.patient,
        loading: false,
        error: false, 
        error_message: '',
        personalInfo: patientDta.personalInfo
      }
      // pase data
      this.parsePatientDataToInputData();

      // let last visitation component load data
      this.$refs.ViewListLastVisitComponent.loadLastVisitAndPayment();
    },

    parsePatientDataToInputData() {
      let personInfo = this.patient.personalInfo;
      this.listInputs = this.listInputs.map(item => {
        switch(item.name) {
          case 'BuyerName':
            item.value = personInfo.fullName
            break;
          case 'BuyerAddress':
            item.value = personInfo.address;
            break;
          case 'ReceiverEmail':
            item.value = personInfo.email;
            break;
          case 'ReceiverMobile': 
            item.value = personInfo.phone;
            break;
          case 'ReceiverAddress':
            item.value = personInfo.address;
            break;
          case 'ReceiverName':
            item.value = personInfo.fullName;
            break;
          default: 
            break;
        }
        return item;
      });
    },
    loadPaymentToInvoiceDetail(item) {
      let finalPrice = item.finalPrice
      this.listDetailInvoice = this.listDetailInvoice.map(i => {
        if (i.order == 1)  {
          i.finalPrice = finalPrice
        }
        return i;
      })
      this.$emit('load-payment-sumary-id', item)
    },
    emitRealDataForCreation() {
      let dataInput = JSON.parse(JSON.stringify(this.listInputs));
      let dataEmit = {};
      dataInput.reduce((obj, item) => {
        obj[item.name] = item.value; 
        return obj;
      }, dataEmit)
      // get list detail invoice
      let listDetailInvoice = JSON.parse(JSON.stringify(this.listDetailInvoice)).map(i => i.data); 
      // add ListInvoiceDetailsWS to dataEmit
      dataEmit.ListInvoiceDetailsWS = listDetailInvoice;
      
      dataEmit.PartnerInvoiceStringID = ""; // default
      dataEmit.PartnerInvoiceID = parseInt(moment().format("YYMMDDHHmmss")); // default

      return dataEmit
    },

    disableDiv(flag) {
      this.saving = flag
    },

    async loadMaxInvoiceNo() {
      var maxInvoice = await InvoiceService.getMaxInvoiceNo(); 
      if (maxInvoice.error) {
        this.$toast.error('Can not get max Invoice Number');
        this.fromInvoiceNo = 0;
        return;
      }
      this.listInputs = this.listInputs.map(i => {
        if (i.name == 'InvoiceNo') {
          i.value = maxInvoice + 1; // next invoice numbert
        }
        return i;
      })
    },

    pushPaymentInfoToListInput(paymentDetailWithService) {
      let {patientInfo, summaryItem} = paymentDetailWithService
     
      var phone = patientInfo.phone.replace(/[+-\s]/gi, '');
      this.patient.personalInfo = {...patientInfo, phone: phone};
      // update patient id 
      this.patient.id = patientInfo.patientID
      // call function to update input
      this.parsePatientDataToInputData();
      // update first tab with final price
      this.$refs.DetailInvoice[0].loadFinalPrice(summaryItem.totalPrice)
      this.$refs.DetailInvoice[0].loadItemNameService(summaryItem.itemName)
    },
    resetListInputToDefault() {
      this.listInputs = this.listInputs.map(i => {
        if (typeof i.default != 'undefined') {
          i.value = i.default
        }
        return i
      })
      // update max Invoice number
      this.loadMaxInvoiceNo()
      // reset list tab
    
      this.listDetailInvoice = [
        {
          order: 1,  // default having 1 invoice
          data: {},
          finalPrice: 0
        }
      ];   
      this.$refs.DetailInvoice[0].resetToDefault();
    }
  }
}
</script>

<style scoped>
 .w-15 {
   width: 15%;
 }
 .w-25 {
   width: 25%;
 }
 .w-50 {
   width: 50%;
 }
 .w-75 {
   width: 75%;
 }
 .savingCreationForm {
   pointer-events: none; 
   filter: blur(1px);
   -webkit-filter: blur(1px);
   cursor: default;
 }
 .disable__click__to__div {
   pointer-events: none;
   filter: blur(1px);
   -webkit-filter: blur(1px);
   position: relative;
 }
 .position-relative {
   position: relative;
 }
 .disabled__label{
   top: 10px;
   right: 10px;
   position: absolute;
 }
 .disableAction {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0; 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .disableAction__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 200px;
  }

  .blurTabContent {
     filter:blur(5px)
  }
</style>